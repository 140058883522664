import { StatusCodes } from 'http-status-codes';
import { useAuthorization } from '~/composables/auth/useAuthorization';
import { ROUTES } from '~/constants/routes';
import { useAuthStore } from '~/stores/auth/useAuthStore';

/** Middleware проверяет авторизацию. */
export default defineNuxtRouteMiddleware(() => {
    // токен на стороне клиента, поэтому серверную обработку пропускаем
    if(import.meta.server) {
        return;
    }
    
    const store = useAuthStore();

    if(!store.isAuthorized) {
        const { redirectToAdminLoginUrl } = useAuthorization();
        return redirectToAdminLoginUrl();
    }

    if(!store.isSettlementOperators) {
        throw createError({
            fatal: true,
            statusCode: StatusCodes.FORBIDDEN,
        });
    }
})